export default {
  // Endpoints
  loginEndpoint: `${process.env.VUE_APP_SERVER_URL}/api/admin/login`,
  registerEndpoint: `${process.env.VUE_APP_SERVER_URL}/api/admin/register`,
  logoutEndpoint: `${process.env.VUE_APP_SERVER_URL}/api/admin/logout`,
  checkLoginEndpoint: `${process.env.VUE_APP_SERVER_URL}/api/admin/check-login`,

  // This will be prefixed in authorization header with token
  // e.g. Authorization: Bearer <token>
  tokenType: "Bearer",

  // Value of this property will be used as key to store JWT token in storage
  storageTokenKeyName: "accessToken",
  storageRefreshTokenKeyName: "refreshToken",
  userNameKey: "userName",
  userEmailKey: "userEmail",
  userClientKey: "showClient",
  userGMBKey: "showGMB",
  userAccessControlKey: "showAccessControl",
  userTriggersKey: "showTriggersKey",
  userDealersKey: "showDealers",
  configurations:"allConfigurations",
  gmbGeo:"geo",
  gmbSummary:"summary",
  gmbOrganic:"organic",
  gmbPaid:"paid",
  gmbPosts:"posts",
  gmbReviews:"reviews",
  gmbStores:"stores",
  adminUrl:"image",
  adminID:"adminId",
  adminFirstName:"firstName",
  adminLastName:"lastName",
  roleForConfiguration:"1"
};
